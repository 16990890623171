import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Layout, Paragraph } from "~/components";
import { chardhamLinks } from "~/constants/links";

const BlogDetails: React.FC = () => {
  return (
    <Layout
      meta="The benefits of embarking on the spiritual journey to Chardham by Helicopter. Read the blog to learn more!"
      links={chardhamLinks}
    >
      <div className="mx-auto text-justify px-10">
        <div className="breadcrumb">
          <div className="pt-4">
            <p className="text-sm text-gray-500 font-medium">
              <a href="/">Home</a> {">"} <a href="/blogs">Blogs</a> {"> "}
              Chardham by Helicopter: Benefits of Helicopter Travel
            </p>
          </div>
        </div>
        <Heading type="h1" className="text-center text-[28px]">
          Chardham by Helicopter: Benefits of Helicopter Travel{" "}
        </Heading>
        <Paragraph>
          The sacred Char Dham Yatra holds immense spiritual significance in the
          personal and cultural consciousness of pilgrims from across Bharat and
          the world over. Therefore, it becomes very crucial for Yatris to
          choose the right mode of travel for the best experience. With years of
          experience and interacting with thousands of customers, we believe
          that the most preferred mode would be:{" "}
          <Link to="/chardham-by-helicopter">'Chardham by Helicopter'</Link>.
          Find out why? Before moving on to the benefits of the{" "}
          <Link to="/chardham-by-helicopter">Chardham Yatra by Helicopter</Link>
          , check out our{" "}
          <Link to="/chardham-by-helicopter/packages/">packages</Link>,
          itinerary, <Link to="/blogs">blogs</Link>,{" "}
          <Link to="/chardham-by-helicopter/#testimonials">testimonials</Link>,{" "}
          <Link to="/chardham-by-helicopter/#photo-gallery">gallery</Link> , and{" "}
          <Link to="https://www.instagram.com/thumbyaviation">
            social media
          </Link>
          .
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/bg/1-chardham-carousel.png"
            alt="Chardham"
            placeholder="blurred"
            objectFit="cover"
            className="w-[400px] h-[300px] text-center"
          />
        </div>
        <Heading type="h2" className="!text-h3">
          The Benefits of Chardham Yatra by Helicopter:
        </Heading>
        <Heading type="h3" className="!text-tertiary !text-md">
          1. Time-efficiency
        </Heading>
        <Paragraph>
          The Chardham Yatra by helicopter significantly reduces travel time,
          allowing devotees to visit all four shrines within a shorter duration
          compared to traditional methods allowing for a more flexible schedule
          and time in spiritual solace.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          2. Comfort and Convenience
        </Heading>
        <Paragraph>
          Traveling by helicopter ensures a comfortable and hassle-free journey,
          bypassing the arduous treks and challenging terrain associated with
          the traditional means of transport.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          3. Exclusive Experience
        </Heading>
        <Paragraph>
          With private charters and personalized services, travelers enjoy an
          exclusive and intimate experience, away from the crowds with priority
          darshan often encountered during the pilgrimage season.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          4. Scenic Views
        </Heading>
        <Paragraph>
          Flying between the clouds and over snow-capped peaks provides
          breathtaking aerial views of the Himalayan landscape, enhancing the
          overall experience of the pilgrimage.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          5. Accessibility
        </Heading>
        <Paragraph>
          Helicopter services make the sacred shrines more accessible to elderly
          devotees and those with physical limitations, allowing them to
          participate in the Chardham Yatra with ease.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          6. Safety
        </Heading>
        <Paragraph>
          Helicopter tours are conducted by experienced operators who employ
          highly qualified team and adhere to strict safety protocols, ensuring
          a secure journey for all passengers.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          7. Spiritual Fulfillment
        </Heading>
        <Paragraph>
          Despite the luxurious mode of travel, the essence of the Chardham
          Yatra remains intact, offering devotees the opportunity to seek
          spiritual solace and blessings at each shrine.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          8. Experienced Pilots
        </Heading>
        <Paragraph>
          Helicopter tours are conducted by highly credentialed and certified
          pilots with significant experience in the armed forces or commercial
          aviation who are well-versed in flying in mountainous terrain.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          9. Regular Maintenance
        </Heading>
        <Paragraph>
          All helicopters undergo regular maintenance checks to ensure they are
          in optimal condition for safe operation.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          10. Weather Monitoring
        </Heading>
        <Paragraph>
          Flights are scheduled based on weather forecasts, and pilots
          constantly monitor weather conditions during the journey to ensure
          safe flying.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          11. Emergency Preparedness
        </Heading>
        <Paragraph>
          Helicopter crews are trained in emergency procedures, including
          evacuations and medical assistance, to handle any unforeseen
          situations.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          12. Communication Systems
        </Heading>
        <Paragraph>
          Advanced communication systems are installed in helicopters to
          maintain constant contact with ground control and other aircraft,
          enhancing safety during the journey.
        </Paragraph>

        <Heading type="h2" className="!text-h3">
          Conclusion:
        </Heading>
        <Paragraph>
          The <Link to="/chardham-by-helicopter/packages/">packages</Link> to
          the <Link to="/chardham-by-helicopter">Chardham helicopter tour</Link>{" "}
          start from arrival at Dehradun airport, traversing the Himalayan peaks
          by helicopter to the temples at Yamunotri, Gangotri, Kedarnath, and
          Badrinath. The helitour packages, include pickup and drop to the
          Dehradun airport, luxurious accommodation, VIP darshan at each temple,
          all meals included with special meal requests, road travel,
          merchandise, and many more customizable options along with our
          helicopter services. In conclusion, based on our experience and
          analysis, the best way to travel to Chardham is by Helicopter. For
          exclusive limited-period offers, a host of customizable options and
          the best experience, choose Thumby aviation and{" "}
          <Link to="?contactUs=true&enquiryType=Chardham Yatra 5N/6D Package">
            book now
          </Link>
          .
        </Paragraph>
        <Paragraph>Thank You!</Paragraph>
      </div>
    </Layout>
  );
};

export default BlogDetails;
